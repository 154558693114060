<template>
  <div
    id="app"
    :class="classTheme"
    v-if="libraryLoaded == true">
    <div v-if="!isEnabledCcp && newIncomingContact">
      <IncomingModal
        :tag="newIncomingContact.type"
        :mdl-data="newIncomingContact"
        :show="showModal"
        @decline="onDismissIncomingCall($event)"
        @accept="answerContact(newIncomingContact)"
        @stop-ringer="stopRinger()"
        @missed="handleMissedChat($event)"
        :is-accepting="$wait.is('connectingCallToAgent')" />
    </div>
    <Loading v-if="isLoadingVisible" />
    <transition
      name="zoom"
      mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import _, { isEmpty } from "lodash";
import IncomingModal from "@/components/incoming-modal/IncomingModal";
import alert_tones from "@/assets/tones";
import alert_tones_secondary from "@/assets/tones-b";
import alert_tones_secondary_main from "@/assets/tones-c";
import { PeerCallStatus } from "@/common/constant";
import Socket from "@/common/socket";
import AmplifyHandler from "./plugins/amplify";
import ActivityService from "./common/services/activity.service.js";
import Amplify from "@aws-amplify/core";
import Loading from "@/components/UI/Loading";
import { oneClickSetup, ContactFilter } from "@/common/constant.js";
import { DARK, LIGHT, CHANNELS } from "@connectpath/common-frontend/src";
import { INITIATION_METHODS } from "@connectpath/common";
import { getTimeObject } from "@/utils/time/getTimeObject";

const amplify = new AmplifyHandler();

let ding = null;
let dingSecondary = null;

export default {
  name: "App",
  data() {
    return {
      libraryLoaded: false,
      initialTimeout: null,
      tones: alert_tones,
      tonesSecondaryMain: alert_tones_secondary_main,
      tonesSecondary: alert_tones_secondary,
      showIncomingModal: false,
      dingPrimaryEmail: null,
      dingSecondaryEmail: null,
      silence: require("./assets/Ringing_Phone.mp3"),
      hasPlayed: false,
      secTimeout: null,
      devices: {
        audio: [],
        video: [],
      },
    };
  },
  components: {
    IncomingModal,
    Loading,
  },
  computed: {
    ...mapGetters("authentication", ["isEnabledCcp"]),
    ...mapState("team", ["voiceCallData"]),
    ...mapState("contact", ["activeVoiceContact", "outboundAttributes"]),
    ...mapGetters("contact", [
      "getMonitorMode",
      "getOutboundAttributesActiveVoiceContact",
      "getInboundAttributesActiveVoiceContact",
    ]),
    ...mapGetters("ui", ["isLoadingVisible"]),
    ...mapGetters("settings", ["isDarkMode"]),
    classTheme() {
      return this.isDarkMode === true ? DARK.CLASS_THEME : LIGHT.CLASS_THEME;
    },
    inboundAttributesActiveVoiceContact() {
      return this.activeVoiceContact;
    },
    outboundAttributesActiveVoiceContact() {
      return this.outboundAttributes;
    },
    mobileView() {
      return this.$store?.state?.mobileView;
    },

    callStatus() {
      return this.$store?.state?.team?.callStatus;
    },

    newSMSContactReceived() {
      return this.$store?.state?.phone?.newSMSContactReceived;
    },

    showSMSContact() {
      return this.$store?.state?.phone?.showSMSContact;
    },

    winWidth() {
      return this.$store?.getters?.winWidth;
    },

    currentUser() {
      return this.$store?.getters?.currentUser;
    },

    agentSummary() {
      return this.$store?.getters?.agentSummary;
    },

    agentStatus() {
      return this.$store?.getters?.agentStatus;
    },

    api() {
      return this.$store?.getters?.api;
    },

    newIncomingContact() {
      let contact = this.$store?.state?.contact?.newIncomingContact;
      return contact;
    },

    getChatRejectedContacts() {
      return this.$store?.state?.contact?.chatRejectedContacts;
    },

    isAcceptingNewSMSContact() {
      return this.$store?.state?.phone?.acceptingSMSContact;
    },

    mediaDevices() {
      return this.$store?.state?.mediaDevices?.audioOutputDevices;
    },
    alerttone() {
      return !this.api.getUser.Preferences.PrimaryRinger.Sound ? "default" : this.api.getUser.Preferences.PrimaryRinger.Sound;
    },
    alerttoneSecondary() {
      return !this.api.getUser.Preferences.SecondaryRinger.Sound
        ? "default"
        : this.api.getUser.Preferences.SecondaryRinger.Sound;
    },
    isEnabledSecondary() {
      return this.api.getUser.Preferences.SecondaryRinger.isEnabled;
      // this.deviceId !== this.deviceIdSecondary
    },
    deviceId() {
      let deviceId = _.get(this.api, "getUser.Preferences.PrimaryRinger.DeviceId");
      let find = this.mediaDevices.find((i) => deviceId === i.deviceId);
      if (find) {
        return find.deviceId;
      } else {
        return "default";
      }
    },
    deviceIdSecondary() {
      let deviceId = _.get(this.api, "getUser.Preferences.SecondaryRinger.DeviceId");
      let find = this.mediaDevices.find((i) => deviceId === i.deviceId);
      if (find) {
        return find.deviceId;
      } else {
        return "default";
      }
    },
    autoAcceptCall() {
      return this.currentUser?.PhoneConfig?.AutoAccept;
    },
    primaryToneVolume() {
      return !this.api.getUser.Preferences.PrimaryRinger.Volume
        ? 50 / 100
        : this.api.getUser.Preferences.PrimaryRinger.Volume / 100;
    },
    secondaryToneVolume() {
      return !this.api.getUser.Preferences.SecondaryRinger.Volume
        ? 50 / 100
        : this.api.getUser.Preferences.SecondaryRinger.Volume / 100;
    },
    language() {
      return this.$store?.state?.settings?.language || "en";
    },
    softPhone() {
      return this.$store?.state?.currentUser?.securityProfile?.SoftPhone;
    },
    showModal() {
      return this.newIncomingContact && !this.getMonitorMode;
    },
  },
  beforeCreate() {
    if (this.$wait) {
      this.$wait.start("initialAppLoad");
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyListener);
    this.sockets.unsubscribe(`${this.agentSummary.Username}-missedVoiceCall`);

    // CHECKING IF THE USER SUBSCRIPTION UPDATE INTERVAL IS RUNNING AND DESTROYING IT.
    if (window.intervalUpdateUser) {
      clearInterval(window.intervalUpdateUser);
      window.intervalUpdateUser = null;
    }
  },

  mounted() {
    this.$store.commit("ui/initUI");
    window.Broadcast.$on("answerContact", () => {
      this.answerContact(this.newIncomingContact);
    });

    window.Broadcast.$on("rejectContact", () => {
      this.onDismissIncomingCall("REJECTED");
    });
    window.isLoggingOut = false;
    document.addEventListener("keydown", this.keyListener.bind(this));

    // send all errors to the error logger
    window.onerror = (msg, source, lineNo, columnNo, error) => {
      let payload = {
        component: "DEXTR",
        level: "ERROR",
        text: error,
      };

      if (payload) {
        this.$store.dispatch("logs/addItem", payload);
      }
    };

    // check if this page was routed to because of a forced mult-session logout

    window.local_track = {
      enabled: false,
    };

    if (this.winWidth <= 1024) {
      this.$store.commit("ui/setSidebarShown", false);
    }

    // Checking If not chrome
    let browserArray = ["Trident", "Edge", "MSIE", "CriOS", "Firefox", "OPR", "Chrome"];
    let browser;
    let userBrowser = window.navigator.userAgent; //browser string

    for (let i = 0; i < browserArray.length; i++) {
      if (userBrowser.includes(browserArray[i])) {
        browser = browserArray[i];
        break;
      }
    }

    this.checkMobileView();

    const isChrome = browser === "Chrome";
    this.initialTimeout = setTimeout(async () => {
      if (!isChrome) {
        this.$Modal.warning({
          title: this.$t("modal.browserCompatibilityIssue"),
          render: (h) => {
            return h("span", [
              `${this.$t("modal.resultsMayVaryOnThisBrowserForBestResultsSeeSystemRequirementsOn")} `,
              h(
                "a",
                { attrs: { href: `${process.env.VUE_APP_DEXTR_DOCUMENTATION_URL}/system-requirements/`, target: "_blank" } },
                `${process.env.VUE_APP_DEXTR_DOCUMENTATION_URL}/system-requirements/`
              ),
            ]);
          },
        });
      }
      const { name } = this.$route;

      const oldStore = JSON.parse(localStorage.getItem("vuex"));
      const subDomain = this.$route.params.subdomain;

      if (name == "Initial page" || subDomain) {
        this.libraryLoaded = true;
      } else if (name) {
        // these must load first and in this order
        await Promise.all([
          import("./plugins/casl.js"),
          import("./plugins/phonenumber-util.js"),
          import("./plugins/v-clipboard.js"),
          import("./plugins/v-tooltip.js"),
          import("./plugins/vue-circular-count-down-timer.js"),
          import("./plugins/vue-fullscreen.js"),
          import("./plugins/vue-observe-visibility.js"),
          import("./plugins/vue-plyr.js"),
          import("./plugins/vue-scrollto.js"),
          import("./plugins/webrtc-adapter.js"),
          import("./plugins/preventRefresh.js"),

          import("./store/modules/hid").then((module) => {
            this.$store.registerModule("hid", module.default);
          }),
          import("./store/modules/iframeIntegrations").then((module) => {
            this.$store.registerModule("iframeIntegrations", module.default);
          }),
          import("./store/modules/integrations").then((module) => {
            this.$store.registerModule("integrations", module.default);
          }),
          import("./store/agent.module").then((module) => {
            this.$store.registerModule("agent", module.default);
          }),
          import("./store/modules/contact").then((module) => {
            this.$store.registerModule("contact", module.default);
          }),
          import("./store/modules/holiday").then((module) => {
            this.$store.registerModule("holiday", module.default);
          }),
          import("./store/modules/security-profile").then((module) => {
            this.$store.registerModule("securityProfile", module.default);
          }),
          import("./store/api.module").then((module) => {
            this.$store.registerModule("api", module.default);
          }),
          import("./store/modules/activity").then((module) => {
            this.$store.registerModule("activity", module.default);
          }),
          import("./store/modules/queue").then((module) => {
            this.$store.registerModule("queue", module.default);
          }),
          import("./store/modules/usage").then((module) => {
            this.$store.registerModule("usage", module.default);
          }),
          import("./store/modules/contacts").then((module) => {
            this.$store.registerModule("contacts", module.default);
          }),
          import("./store/modules/users").then((module) => {
            this.$store.registerModule("users", module.default);
          }),
          import("./store/modules/team").then((module) => {
            this.$store.registerModule("team", module.default);
          }),
          import("./store/modules/phone").then((module) => {
            this.$store.registerModule("phone", module.default);
          }),
          import("./store/modules/health").then((module) => {
            this.$store.registerModule("health", module.default);
          }),
          import("./store/modules/current-user").then((module) => {
            this.$store.registerModule("currentUser", module.default);
          }),
          import("./store/modules/chat").then((module) => {
            this.$store.registerModule("chat", module.default);
          }),
          import("./store/modules/channel").then((module) => {
            this.$store.registerModule("channel", module.default);
          }),
          import("./store/modules/mediaDevices").then((module) => {
            this.$store.registerModule("mediaDevices", module.default);
          }),
          import("./store/modules/reports").then((module) => {
            this.$store.registerModule("reports", module.default);
          }),
          import("./store/modules/new-reports").then((module) => {
            this.$store.registerModule("newReports", module.default);
          }),
          import("./store/modules/announcement").then((module) => {
            this.$store.registerModule("announcement", module.default);
          }),
          import("./store/modules/tableConfig").then((module) => {
            this.$store.registerModule("tableConfig", module.default);
          }),
          import("./store/modules/support").then((module) => {
            this.$store.registerModule("support", module.default);
          }),
          import("./store/modules/self-service").then((module) => {
            this.$store.registerModule("selfService", module.default);
          }),
          import("./store/modules/translations").then((module) => {
            this.$store.registerModule("translations", module.default);
          }),
          import("./store/modules/livelook").then((module) => {
            this.$store.registerModule("livelook", module.default);
          }),
          import("./store/modules/surveys").then((module) => {
            this.$store.registerModule("surveys", module.default);
          }),
          import("./store/modules/videoCall").then((module) => {
            this.$store.registerModule("videoCall", module.default);
          }),
        ]);

        if (oldStore) {
          // Load old store
          const oldState = {
            staticNumbers: oldStore.staticNumbers,
            wWidth: oldStore.wWidth,
            role: oldStore.role,
            initialized: oldStore.initialized,
            isAuthenticating: oldStore.isAuthenticating,
            isConnected: oldStore.isConnected,
            isFetchingAuthenticating: oldStore.isFetchingAuthenticating,
            ccpInstanceId: oldStore.ccpInstanceId,
            isDissmissTestCall: oldStore.isDissmissTestCall,
            isLoading: oldStore.isLoading,
            isModalActive: oldStore.isModalActive,
            isVolumnSilent: oldStore.isVolumnSilent,
            isNitroMode: oldStore.isNitroMode,
            isVolumnControllShow: oldStore.isVolumnControllShow,
            isMicControllShow: oldStore.isMicControllShow,
            isMicSilent: oldStore.isMicSilent,
            isShowTeamStatus: oldStore.isShowTeamStatus,
            isShowAlertsDrawer: oldStore.isShowAlertsDrawer,
            hasAlerts: oldStore.hasAlerts,
            rules: oldStore.rules,
            enableBtnAws: oldStore.enableBtnAws,
            lockDashboardDrag: oldStore.lockDashboardDrag,
            soundVolume: oldStore.soundVolume,
            api: oldStore.api,
            globalCallDuration: oldStore.globalCallDuration,
            dashboardCards: oldStore.dashboardCards,
          };
          this.$store.commit("updateState", oldState);
          this.$store.commit("updateAgentState", oldStore.agent);
          this.$store.commit("contact/updateState", oldStore.contact);
          this.$store.commit("holiday/updateState", oldStore.holiday);
          this.$store.commit("securityProfile/updateState", oldStore.securityProfile);
          this.$store.commit("updateApiState", oldStore.api);
          this.$store.commit("queue/updateState", oldStore.queue);
          this.$store.commit("contacts/updateState", oldStore.contacts);
          this.$store.commit("settings/updateState", oldStore.settings);
          this.$store.commit("currentUser/updateState", oldStore.currentUser);
          this.$store.commit("mediaDevices/updateState", oldStore.mediaDevices);
          this.$store.commit("tableConfig/updateState", oldStore.tableConfig);

          await amplify.configure(oldStore.currentUser.profile.Region || "us-east-1");
          try {
            const { idToken } = await Amplify.Auth.currentSession();
            Socket.setToken(idToken);
            Socket.open();
          } catch (err) {
            console.error(err);
          }

          if (Socket || Socket.isReady()) {
            Socket.$on(`${this.agentSummary.Username}-missedVoiceCall`, (data) => {
              this.onFetchRecentActivity(this.agentSummary);
            });
          }
        }
        this.libraryLoaded = true;
      }
    }, 1500);
    this.secTimeout = setTimeout(() => {
      if (!this.libraryLoaded) {
        this.$Modal.warning({
          title: this.$t("notifications.loadFailed"),
          content: this.$t("notifications.agentInitialisationDuplicatedQuestion"),
          onOk: async () => {
            try {
              this.$Modal.remove();
              this.$router.go();
            } catch (ex) {
              console.error(ex);
            }
          },
        });
      }
    }, 4000);
  },

  destroyed() {
    localStorage.removeItem("store-initialized");
    clearTimeout(this.initialTimeout);
    clearTimeout(this.secTimeout);
  },
  watch: {
    winWidth() {
      this.checkMobileView();
    },
    newIncomingContact(newContact, oldContact) {
      if (!newContact) {
        this.$Modal.remove();
      }
      if (newContact && oldContact !== newContact && !this.isEnabledCcp) {
        if (this.autoAcceptCall) {
          this.answerContact(newContact);
          return;
        }

        if (this.isOutboundTask(newContact)) {
          this.answerContact(newContact);
          return;
        }

        const isOnAgentCall = this.callStatus === PeerCallStatus.Connecting || this.callStatus === PeerCallStatus.Connected;
        // this.$wait.end("connectingCallToAgent");
        this.$wait.end("rejectingContact");

        if (isOnAgentCall) {
          this.$Modal.confirm({
            title: this.$t("common.warning"),
            content: this.$t("modal.youHaveAnIncomingCallDoYouWantToDropTheCurrentAgentCallAndReceiveTheNewOneInstead"),
            onOk: () => {
              this.handleEndAgentCall();
              this.answerContact(newContact);
            },
            onCancel: () => {
              this.onDissmissIncomingCallUI(newContact);
            },
          });
          return;
        } else {
          this.$wait.end("connectingCallToAgent");
          if (newContact.type === "email") {
            this.playIncomingEmailRingtone();
            if (_.get(this.api, "getUser.Preferences.SecondaryRingerEmail.isEnabled")) {
              this.playIncomingEmailSecondaryRingtone();
            }
          } else {
            if (!this.getMonitorMode) this.playTone(this.alerttone, true);
            console.log("#EM - voice secondary enabled : ", this.isEnabledSecondary);
            if (this.isEnabledSecondary) {
              if (!this.getMonitorMode) this.playToneSecondary(this.alerttoneSecondary, true);
            }
          }

          // this.broadcastStatus({ status: { name: "PendingBusy" } });
        }
      }
    },
    agentStatus: {
      immediate: true,
      handler(agentStatus, oldStatus) {
        if (agentStatus) {
          if (agentStatus.name === "MissedCallAgent" || agentStatus.name === "MissedCallCustomer") {
            // TODO make a dummy data instead of requesting
            this.onDismissIncomingCall("MISSED");
            this.showIncomingModal = false;
          }
          if (agentStatus.name === "FailedConnectCustomer") {
            this.$wait.end("connectingCallToAgent");
            this.onDismissIncomingCall("MISSED");
            return (this.showIncomingModal = false);
          }
        }
      },
    },
    language(newLanguage, oldLanguage) {
      if (newLanguage && newLanguage !== oldLanguage) {
        this.$i18n.locale = newLanguage;
      }
    },
    softPhone() {
      const showNativeCcp = window.localStorage.getItem("showNativeCcp") === "true";
      if (this.softPhone === "ccpclient" && !showNativeCcp) {
        window.localStorage.setItem("showNativeCcp", true);
        window.location.href = "/";
      }
      if (this.softPhone === "dextr" && showNativeCcp) {
        window.localStorage.setItem("showNativeCcp", false);
        window.location.href = "/";
      }
    },
    inboundAttributesActiveVoiceContact: {
      handler(newActiveVoiceContact) {
        if (newActiveVoiceContact) {
          const customAttributesActiveVoiceContact = this.getInboundAttributesActiveVoiceContact;
          this.getUrlsIntegrationFormated({
            customAttributesActiveVoiceContact,
          });
        }
      },
    },
    outboundAttributesActiveVoiceContact: {
      handler(newValue, oldValue) {
        if (!isEmpty(newValue) && isEmpty(oldValue)) {
          const customAttributesActiveVoiceContact = newValue;
          this.getUrlsIntegrationFormated({
            customAttributesActiveVoiceContact,
          });
        }
      },
    },
  },

  methods: {
    ...mapActions("hid", ["sendCommandWithArguments"]),
    ...mapActions("team", [
      "endVoiceCall",
      "destroyVoiceCall",
      "endVoiceCallFromCallee",
      "stopIncomingRingtone",
      "destroyPeer",
    ]),
    ...mapActions("phone", [
      "onNewSmsContact",
      "subscribeToAllOngoingSmsContacts",
      "unsubscribeToAllOnNewSmsMessageSubscription",
    ]),
    ...mapActions("channel", ["onNewEmailContact", "unsubscribeToAllOnNewEmailMessageSubscription"]),

    ...mapMutations("phone", ["endSubscription"]),
    ...mapMutations("channel", ["endEmailSubscription"]),
    ...mapActions("activity", ["onFetchRecentActivity"]),
    ...mapActions("iframeIntegrations", ["getUrlsIntegrationFormated"]),

    keyListener(event) {},

    isOutboundTask(contact) {
      return (
        (contact.type === "sms" || contact.type === "email") &&
        !contact.isInbound &&
        contact.data?.attributes?.Direction?.value === "Outbound"
      );
    },

    checkMobileView() {
      const body = document.getElementsByTagName("body")[0];
      if (this.mobileView) {
        body.classList.add("mobile-body");
      } else {
        body.classList.remove("mobile-body");
      }
    },
    dummyCallFormProps() {
      let randomNumber = this.dummyPhoneNumbers[this.generateRandomNumber(0, this.dummyPhoneNumbers.length - 1)];
      let randomQueue = this.dummyQueue[this.generateRandomNumber(0, this.dummyQueue.length - 1)];
      return {
        phoneNumber: randomNumber,
        queue: randomQueue,
      };
    },
    generateRandomNumber(min = 0, max = 1) {
      return Math.round(Math.random() * (max - min) + min);
    },
    async onDismissIncomingCall(value) {
      this.$wait.start("rejectingContact"); // This is ended in a watcher
      if (this.newIncomingContact && this.newIncomingContact.type === "chat") {
        return this.declineIncomingChat();
      }
      if (this.newIncomingContact) {
        let contact = { ...this.newIncomingContact };

        const { EMAIL, CHAT, SMS, TASK } = CHANNELS;
        const { MISSED, REJECTED } = INITIATION_METHODS;
        if (contact.type === EMAIL || contact.type === CHAT || contact.type === SMS || contact.type === TASK) {
          if (value === MISSED || value === REJECTED) {
            contact.summary.InitiationTimestamp = getTimeObject().offsetToISOString();
            contact.DisconnectTimestamp = contact.summary.InitiationTimestamp;
            contact["InitiationMethod"] = value;
          }
          let activityService = new ActivityService();
          await activityService.postActivity(contact);
          await this.onFetchRecentActivity(this.agentSummary);
        }

        this.newIncomingContact.reject();
        this.$store.commit("contact/clearIncomingContact");
      }
      this.$store.dispatch("team/stopIncomingRingtone", null, { root: 1 });
      if (ding) ding.pause();
      if (dingSecondary) dingSecondary.pause();
      this.stopRinger();
      this.$wait.end("rejectingContact");
    },
    handleMissedChat() {
      this.$store.commit("contact/clearIncomingContact");
      this.$store.dispatch("team/stopIncomingRingtone", null, { root: 1 });
      if (ding) ding.pause();
      if (dingSecondary) dingSecondary.pause();
      this.stopRinger();
      this.$wait.end("rejectingContact");
    },
    declineIncomingChat() {
      // This is ended in a watcher
      this.newIncomingContact.reject();
      this.$store.commit("contact/clearIncomingContact");
      this.$store.dispatch("team/stopIncomingRingtone", null, { root: 1 });
      if (ding) ding.pause();
      if (dingSecondary) dingSecondary.pause();
      this.stopRinger();
      this.$wait.end("rejectingContact");
    },
    stopRinger() {
      if (ding) {
        ding.pause();
        ding = null;
      }
      if (dingSecondary) {
        dingSecondary.pause();
        dingSecondary = null;
      }
      if (this.dingPrimaryEmail) {
        this.dingPrimaryEmail.pause();
        this.dingPrimaryEmail = null;
      }
      if (this.dingSecondaryEmail) {
        this.dingSecondaryEmail.pause();
        this.dingSecondaryEmail = null;
      }
      this.sendCommandWithArguments({ cmd: "ringCmd", value: false });
    },
    async answerContact(contact) {
      try {
        try {
          this.$store.dispatch("logs/addItem", {
            component: "DEXTR",
            level: "DEBUG",
            text: `Agent clicked accept contact (${contact?.contactId})`,
          });

          this.sendCommandWithArguments({ cmd: "callCmd", value: true });

          const { summary, taskSource, attributes } = contact;
          if (
            summary.type === ContactFilter.TASK &&
            taskSource === oneClickSetup.dextr &&
            attributes?.Channel !== ContactFilter.CUSTOMTASK &&
            summary?.ChannelName !== oneClickSetup.name
          ) {
            await contact.listMessages();
          }
        } catch (error) {
          console.error(error);
        }

        this.$wait.start("connectingCallToAgent"); // This is ended in a watcher

        this.stopRinger();

        await contact.accept().catch((err) => {
          throw err;
        });

        this.$store.commit("contact/clearIncomingContact");

        this.$wait.end("connectingCallToAgent");

        try {
          let tabname;
          switch (contact.type) {
            case "chat":
              tabname = "chat";
              break;
            case "voice":
              tabname = "phone";

              let initialConnection = contact?.root?.getInitialConnection();
              let initialConnectionState = initialConnection?.getStatus();

              if (!initialConnection || initialConnectionState?.type?.toLowerCase() == "disconnected") {
                this.$store.dispatch("logs/addItem", {
                  component: "DEXTR",
                  level: "DEBUG",
                  text: `Caller hung up (${contact?.contactId}) before agent could accept`,
                });
              }

              break;
            case "sms":
              tabname = "task";
              break;
            case "task":
              tabname = "task";
              break;
            case "email":
              tabname = "task";
              break;
            case "queue_callback":
              tabname = "phone";
              break;
            default:
              tabname = contact.type;
          }

          let id = contact?.attributes?.ConversationId?.value || contact.contactId;

          if (!this.$route.query.set) {
            this.$router.push(
              `/engage?tab=${tabname}${
                contact.type === "voice" || contact.type === "queue_callback" || contact.type === "chat" ? "" : "&set=" + id
              }`
            );
          }
        } catch (error) {
          console.error(error);
        }
        if (contact.type === CHANNELS.TASK || contact.type === CHANNELS.SMS) {
          let activityService = new ActivityService();
          await activityService.postActivity(contact);
        }
        await this.onFetchRecentActivity(this.agentSummary);
      } catch (err) {
        console.error(err);

        this.stopRinger();
        this.$store.commit("contact/clearIncomingContact");
        this.$wait.end("connectingCallToAgent");

        this.$store.dispatch("logs/addItem", {
          component: "DEXTR",
          level: "ERROR",
          text: `Error accepting contact (${contact?.contactId})`,
        });
        this.$store.dispatch("logs/addItem", {
          component: "DEXTR",
          level: "ERROR",
          text: err?.name + ": " + err?.message,
        });
      }
    },
    handleEndAgentCall() {
      // if this is the caller
      if (this.voiceCallData && this.voiceCallData.initiator === this.currentUser?.Username) {
        // if the WebRTC connection is not established yet
        if (this.callStatus === PeerCallStatus.Connecting || this.callStatus === PeerCallStatus.Connected) {
          // send an event to let the callee knows so that they can end the waiting
          this.endVoiceCall({
            InstanceId: this.agentSummary.InstanceId,
          });
        }

        // reset the current WebRTC setup and destroy the connection
        this.destroyVoiceCall();
        this.$store.commit("chat/setDrawer", false);
        this.$store.commit("chat/setUserSelected", null);
      } else if (this.voiceCallData && this.voiceCallData.callee === this.currentUser?.Username) {
        // this is the callee
        if (this.callStatus === PeerCallStatus.Connecting || this.callStatus === PeerCallStatus.Connected) {
          // send an event to let the caller knows so that they can end the waiting
          this.endVoiceCallFromCallee({ rejected: false });
          this.stopIncomingRingtone();
        }
        this.destroyPeer();
        // reset the current WebRTC setup and destroy the connection
        this.$store.commit("chat/setDrawer", false);
        this.$store.commit("chat/setUserSelected", null);
      }
      // else {
      // }
    },
    async onDissmissIncomingCallUI(contact) {
      this.$store.dispatch("contact/destroyContact", contact);
      this.onDismissIncomingCall("REJECTED");
    },
    playTone(v, isRepeat) {
      for (var el in this.tones) {
        if (Object.prototype.hasOwnProperty.call(this.tones, el)) {
          if (el == v) {
            ding = new Audio(this.tones[el]);
            if (typeof ding.setSinkId === "function") {
              ding.setSinkId(this.deviceId);
            }
            if (isRepeat) ding.loop = true;
            ding.volume = this.primaryToneVolume;
            ding.play();
          }
        }
      }
    },
    playToneSecondary(v, isRepeat) {
      try {
        dingSecondary = new Audio(this.tonesSecondaryMain[v]);
        if (typeof dingSecondary.setSinkId === "function") {
          dingSecondary.setSinkId(this.deviceIdSecondary);
        }
        dingSecondary.volume = this.secondaryToneVolume;
        dingSecondary.play();
        dingSecondary.loop = true;
      } catch (err) {
        console.error("error playing secondary tone: ", err);
      }
    },

    playIncomingEmailRingtone(v, isRepeat) {
      let deviceId = _.get(this.api, "getUser.Preferences.PrimaryRingerEmail.DeviceId");

      let find = this.mediaDevices.find((i) => deviceId === i.deviceId);
      if (find) {
        deviceId = find.deviceId;
      } else {
        deviceId = "default";
      }

      const ringToneName = _.get(this.api, "getUser.Preferences.PrimaryRingerEmail.Sound") || "default";

      this.dingPrimaryEmail = null;
      for (let el in this.tones) {
        if (Object.prototype.hasOwnProperty.call(this.tones, el)) {
          if (el == ringToneName) {
            this.dingPrimaryEmail = new Audio(this.tones[el]);
            if (typeof this.dingPrimaryEmail.setSinkId === "function") {
              this.dingPrimaryEmail.setSinkId(deviceId);
            }
            this.dingPrimaryEmail.loop = true;
            this.dingPrimaryEmail.volume = (_.get(this.api, "getUser.Preferences.PrimaryRingerEmail.Volume") || 50) / 100;
            this.dingPrimaryEmail.play();
          }
        }
      }
    },

    playIncomingEmailSecondaryRingtone(v, isRepeat) {
      //stop current playing ringtone, before starting it again

      let secondaryDeviceID = _.get(this.api, "getUser.Preferences.SecondaryRingerEmail.DeviceId") || "default";

      let find = this.mediaDevices.find((i) => secondaryDeviceID === i.deviceId);
      if (find) {
        secondaryDeviceID = find.deviceId;
      } else {
        secondaryDeviceID = "default";
      }

      const ringToneNameSecondary = _.get(this.api, "getUser.Preferences.SecondaryRingerEmail.Sound") || "default";

      const ringToneSecondary = this.tonesSecondaryMain[ringToneNameSecondary];
      this.dingSecondaryEmail = null;
      this.dingSecondaryEmail = new Audio(ringToneSecondary);
      if (typeof this.dingSecondaryEmail.setSinkId === "function") {
        this.dingSecondaryEmail.setSinkId(secondaryDeviceID);
      }
      this.dingSecondaryEmail.volume = (_.get(this.api, "getUser.Preferences.SecondaryRingerEmail.Volume") || 50) / 100;
      this.dingSecondaryEmail.loop = true;
      this.dingSecondaryEmail.play();
    },

    onDismissIncomingSMS() {
      this.$store.commit("phone/newSMSContactReceived", null, { root: 1 });
      this.$store.commit("phone/setShowSMSContact", false, { root: 1 });
    },
    onAcceptNewSMS() {
      let InstanceId = this.newSMSContactReceived.InstanceId;
      let ContactId = this.newSMSContactReceived.id;
      let Accept = true;
      let Username = this.agentSummary.Username;

      this.$store
        .dispatch("phone/acceptSmsConversation", {
          InstanceId,
          ContactId,
          Accept,
          Username,
        })
        .then((res) => this.onSuccessfulAcceptNewSMS());

      this.$store.dispatch("phone/stopIncomingSmsRingtone");

      const newActivity = {
        Channel: "sms",
        InitiationMethod: "INBOUND",
        CustomerEndpoint: this.newSMSContactReceived.From,
        InitiationTimestamp: getTimeObject().getOffsetTime(),
        Queue: this.newSMSContactReceived.MatchedQueueName,
        id: this.newSMSContactReceived.id,
      };
      this.$store.commit("activity/updateOrNewListRecentActivity", newActivity);
    },
    onSuccessfulAcceptNewSMS() {
      this.$store.commit("phone/onNewContactReceived", this.newSMSContactReceived);
      this.$router.push("/engage?tab=task&set=" + this.newSMSContactReceived.id);
      this.$store.commit("phone/setSelectedThread", this.newSMSContactReceived.id, {
        root: 1,
      });
      this.$store.dispatch(
        "phone/onNewSmsMessage",
        {
          InstanceId: this.newSMSContactReceived.InstanceId,
          ContactId: this.newSMSContactReceived.id,
        },
        { root: 1 }
      );
      this.$store.commit("phone/newSMSContactReceived", null, { root: 1 });
      this.$store.commit("phone/setShowSMSContact", false, { root: 1 });
    },
    onDeclineNewSMS() {
      let id = _.clone(this.newSMSContactReceived.id);
      let InstanceId = this.newSMSContactReceived.InstanceId;
      let ContactId = this.newSMSContactReceived.id;
      let Accept = false;
      let Username = this.agentSummary.Username;

      this.$store
        .dispatch("phone/acceptSmsConversation", {
          InstanceId,
          ContactId,
          Accept,
          Username,
        })
        .finally(() => {
          this.$store.commit("phone/removeConvo", { id });
        });

      this.$store.dispatch("phone/stopIncomingSmsRingtone");
      this.onDismissIncomingSMS();
    },
    subscribeIncoming() {
      let InstanceId = this.agentSummary.InstanceId;
      let Username = this.agentSummary.Username;

      this.onNewSmsContact({ InstanceId, Username });
      this.onNewEmailContact({ InstanceId, Username });

      this.subscribeToAllOngoingSmsContacts();
      this.subscribeToAllOngoingEmailContacts();
    },
    unsubscribeIncoming() {
      this.endSubscription({ name: "onNewSmsContact" });
      this.endEmailSubscription({ name: "onNewEmailContact" });
      this.unsubscribeToAllOnNewEmailMessageSubscription();
      this.unsubscribeToAllOnNewSmsMessageSubscription();
    },
  },
};
</script>

<style lang="scss">
@import "~vue-tel-input/dist/vue-tel-input.css";
@import "./assets/scss/master";

.ivu-table-cell {
  word-break: break-word !important;
}

.dark-app {
  background: #36393f !important;
  .m-page-wrapper {
    background: #36393f !important;
    height: 100vh;
  }
  *::-webkit-scrollbar {
    width: 10px !important;
  }
  *::-webkit-scrollbar-track {
    background: #2c2f33;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #202225;
    border-radius: 10px;
  }

  table tr {
    th {
      background: #23272a !important;
      color: white;
      border-color: #23272a;
    }
    td {
      background: #2c2f33 !important;
      color: white;
      border-color: #23272a;
      strong {
        color: white;
      }
    }
  }
  .ivu-table-wrapper {
    border-color: #23272a;
    .ivu-table {
      background: #36393f !important;
    }
  }

  .ivu-table-fixed-right::before,
  .ivu-table-fixed::before,
  .ivu-table:after,
  .ivu-table:before {
    background: #23272a !important;
  }
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  100% {
    opacity: 1;
  }
}
</style>
